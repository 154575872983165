/** @jsx jsx */
import { Box, Flex, jsx } from 'theme-ui'
import useNotificationBarQuery from '~/hooks/graphql/queries/use-notification-bar'

const NotificationBar = () => {
  const { notificationBar } = useNotificationBarQuery()

  const notification = notificationBar && notificationBar.notification

  if (!notification) return null

  return (
    <Flex
      sx={{
        alignItems: 'center',
        backgroundColor: 'secondary',
        minHeight: ['48px', null, '60px'],
        textAlign: 'center',
        justifyContent: 'space-around',
        variant: 'text.link'
      }}
    >
      <Box
        dangerouslySetInnerHTML={{
          __html: notification.description.childMarkdownRemark.html
        }}
      />
    </Flex>
  )
}

export default NotificationBar
