import PropTypes from 'prop-types'
import React from 'react'
import Nav from '~/components/Nav'
import Footer from '~/components/Footer'
import NotificationBar from '~/components/Notification/Bar'

const Layout = ({ children }) => {
  return (
    <>
      <Nav />
      <NotificationBar />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
