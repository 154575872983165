/** @jsx jsx */
import { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { Box, jsx } from 'theme-ui'

const NavMenuDesktopCartLink = ({ children, itemCount }) => {
  const [showItemCount, setShowItemCount] = useState(false)

  useEffect(() => {
    setShowItemCount(typeof itemCount === 'number')
  }, [itemCount])

  return (
    <Link
      key="cart"
      to="/cart/"
      sx={{
        color: 'inherit',
        opacity: showItemCount ? 1 : 0,
        variant: 'text.navLink',
        width: '200px',
        textAlign: 'center',
        '& div': {
          display: 'inline-block',
          height: '1.75rem',
          width: '1.75rem',
          boxSizing: 'border-box',
          border: '1px solid',
          borderRadius: '100%',
          borderColor: 'inherit',
          flexShrink: 0,
          textAlign: 'center',
          lineHeight: '26px',
          paddingLeft: '1px'
        }
      }}
    >
      {children}
      <Box sx={{ opacity: showItemCount ? 1 : 0 }}>{itemCount || 0}</Box>
    </Link>
  )
}

NavMenuDesktopCartLink.propTypes = {
  itemCount: PropTypes.number
}

export default NavMenuDesktopCartLink
