/** @jsx jsx */
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { Flex, Text, jsx } from 'theme-ui'

const FooterLegal = ({ links }) => {
  return (
    <Flex
      sx={{
        flexDirection: ['column', 'row'],
        fontSize: 14,
        justifyContent: 'center',
        lineHeight: ['25px', 'inherit'],
        textAlign: ['center', 'left']
      }}
    >
      {links.map(({ name, slug }) => {
        return (
          <Text key={slug} sx={{ margin: ['0 0 1rem 0', '0 0.75rem'] }}>
            <Link to={`/${slug}/`}>{name}</Link>
          </Text>
        )
      })}
      <Text sx={{ margin: ['0 0 1rem 0', '0 0 0 0.75rem'] }}>
        © Chord Commerce {new Date().getFullYear()}
      </Text>
    </Flex>
  )
}

FooterLegal.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string
    })
  )
}

export default FooterLegal
