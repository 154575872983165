/** @jsx jsx */
import { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { Box, jsx } from 'theme-ui'

const NavMenuCartLink = ({ children, itemCount }) => {
  const [showItemCount, setShowItemCount] = useState(false)

  useEffect(() => {
    setShowItemCount(typeof itemCount === 'number')
  }, [itemCount])

  return (
    <Link
      key="cart"
      to="/cart/"
      sx={{
        flexShrink: 0,
        variant: 'text.navLink',
        opacity: showItemCount ? 1 : 0,
        height: '1.75rem',
        width: '1.75rem',
        boxSizing: 'border-box',
        border: '1px solid',
        borderRadius: '100%',
        lineHeight: '26px',
        paddingLeft: '2px',
        textAlign: 'center'
      }}
    >
      <Box>{itemCount || 0}</Box>
      {children}
    </Link>
  )
}

NavMenuCartLink.propTypes = {
  itemCount: PropTypes.number
}

export default NavMenuCartLink
