/** @jsx jsx */
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { jsx } from 'theme-ui'

const NavMenuLink = ({ page }) => {
  return (
    <Link
      key={page.id}
      to={`/${page.slug}/`}
      sx={{
        variant: 'text.navLink',
        color: 'inherit',
        borderTop: ['1px solid', 'none'],
        lineHeight: '3rem',
        width: ['100%', 'auto'],
        padding: ['0 1rem', '0'],
        '&:last-child': {
          borderBottom: ['1px solid', 'none']
        }
      }}
    >
      {page.name}
    </Link>
  )
}

NavMenuLink.propTypes = {
  page: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired
  })
}

export default NavMenuLink
