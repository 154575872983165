/** @jsx jsx */
import PropTypes from 'prop-types'
import { Container, Flex, jsx } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-performance'
import NavMenuDesktopLink from './Link'
import NavMenuDesktopCartLink from './CartLink'
import NavMenuDesktopLogo from './Logo'

const NavMenuDesktop = ({ pages, itemCount }) => {
  const leftLinks = pages.slice(0, Math.round(pages.length / 2))
  const rightLinks = pages.slice(Math.round(pages.length / 2), pages.length)
  const translate = useTranslate()

  return (
    <Container
      variant="fullWidth"
      sx={{
        alignItems: 'center',
        display: ['none', null, 'flex'],
        padding: '1rem 0',
        width: '100%',
        transition: 'height .25s ease, box-shadow .25s ease',
        height: '128px',
        boxShadow: 'none'
      }}
    >
      <Flex sx={{ justifyContent: 'flex-end', width: '50%' }}>
        {leftLinks.map(page => (
          <NavMenuDesktopLink page={page} key={page.id} />
        ))}
      </Flex>

      <NavMenuDesktopLogo />

      <Flex sx={{ justifyContent: 'flex-start', width: '50%' }}>
        {rightLinks.map(page => (
          <NavMenuDesktopLink page={page} key={page.id} />
        ))}

        <NavMenuDesktopCartLink itemCount={itemCount}>
          {translate('navigation.cart')}{' '}
        </NavMenuDesktopCartLink>
      </Flex>
    </Container>
  )
}

NavMenuDesktop.propTypes = {
  pages: PropTypes.array,
  itemCount: PropTypes.number
}
export default NavMenuDesktop
