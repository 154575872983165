import { graphql, useStaticQuery } from 'gatsby'

const useNotificationBarQuery = () => {
  const data = useStaticQuery(graphql`
    {
      notificationBar: contentfulNotificationBar(
        slug: { eq: "notification-bar" }
      ) {
        notification {
          slug
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `)

  return data
}

export default useNotificationBarQuery
