/** @jsx jsx */
import { Link } from 'gatsby'
import { jsx } from 'theme-ui'
import Logo from '~/assets/images/logos/logo.svg'

const NavMenuDesktopLogo = () => {
  return (
    <Link
      to="/"
      sx={{
        flexShrink: 0,
        padding: '0 2rem',
        '& svg': { height: '4rem', width: 'auto' }
      }}
      aria-label="Home"
    >
      <Logo sx={{ marginTop: '-16px' }}></Logo>
    </Link>
  )
}

export default NavMenuDesktopLogo
