/** @jsx jsx */
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { Box, Flex, Text, jsx } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-performance'

const FooterLinks = ({ catalog, links }) => {
  const translate = useTranslate()

  return (
    <Flex
      sx={{
        borderBottom: [null, '1px solid', 'none'],
        flexDirection: ['column', 'row'],
        textAlign: ['center', 'left'],
        width: ['100%', null, '50%']
      }}
    >
      <Box
        sx={{
          borderBottom: ['1px solid', 'none'],
          lineHeight: ['35px', 'inherit'],
          padding: ['1.5rem 0', null, '0'],
          width: '100%'
        }}
      >
        <Text variant="link" sx={{ marginBottom: '1rem' }}>
          {translate('footer.shop.title')}
        </Text>
        <Text sx={{ marginBottom: '0.25rem' }}>
          <Link to="/shop/">{translate('footer.shop.shop_all')}</Link>
        </Text>
        {catalog.collections.map(({ title, slug }) => {
          return (
            <Text sx={{ marginBottom: '0.25rem' }} key={slug}>
              <Link to={`/shop/?collection=${slug}`}>{title}</Link>
            </Text>
          )
        })}
      </Box>
      <Box
        sx={{
          borderBottom: ['1px solid', 'none'],
          lineHeight: ['35px', 'inherit'],
          padding: ['1.5rem 0', null, '0'],
          width: '100%'
        }}
      >
        <Text variant="link" sx={{ marginBottom: '1rem' }}>
          {translate('footer.company.title')}
        </Text>
        {links.map(({ name, slug }) => {
          return (
            <Text key={slug} sx={{ marginBottom: '0.25rem' }}>
              <Link to={`/${slug}/`}>{name}</Link>
            </Text>
          )
        })}
      </Box>
      <Box
        sx={{
          borderBottom: ['1px solid', 'none'],
          lineHeight: ['25px', 'inherit'],
          padding: ['1.5rem 0', null, '0'],
          minWidth: 'fit-content',
          width: '100%'
        }}
      >
        <Text variant="link" sx={{ marginBottom: '1rem' }}>
          {translate('footer.contact.title')}
        </Text>
        <Text sx={{ marginBottom: '0.25rem' }}>
          {translate('footer.contact.general_inquiries')}
        </Text>
        <Text sx={{ marginBottom: '1.25rem' }}>
          <a href="mailto:hello@chord.co">hello@chord.co</a>
        </Text>
        <Text sx={{ marginBottom: '0.25rem' }}>
          {translate('footer.contact.press_inquiries')}
        </Text>
        <Text>
          <a href="mailto:press@chord.co">press@chord.co</a>
        </Text>
      </Box>
    </Flex>
  )
}

FooterLinks.propTypes = {
  catalog: PropTypes.shape({
    collections: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        slug: PropTypes.string
      })
    )
  }),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string
    })
  )
}

export default FooterLinks
