/** @jsx jsx */
import PropTypes from 'prop-types'
import { Box, jsx } from 'theme-ui'
import NavMenu from '~/components/Nav/Menu'
import NavMenuDesktop from '~/components/Nav/MenuDesktop'

export const NavBar = ({ pages, itemCount }) => {
  const pagesToLinkTo = [
    ...pages,
    { id: 'account', slug: 'account', name: 'Account' }
  ]

  return (
    <Box
      as="nav"
      role="navigation"
      sx={{
        backgroundColor: 'background',
        overflow: 'visible',
        width: '100%'
      }}
    >
      <NavMenuDesktop pages={pagesToLinkTo} itemCount={itemCount} />
      <NavMenu pages={pagesToLinkTo} itemCount={itemCount} />
    </Box>
  )
}

NavBar.propTypes = {
  itemCount: PropTypes.number,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired
    })
  )
}

export default NavBar
