/** @jsx jsx */
import { Box, Container, Flex, jsx } from 'theme-ui'
import FooterLegal from '~/components/Footer/Legal'
import FooterLinks from '~/components/Footer/Links'
import FooterNewsletterSignup from '~/components/Footer/NewsletterSignup'
import FooterSocial from '~/components/Footer/Social'
import FooterDataConsent from '~/components/Footer/DataConsent'
import useFooterQuery from '~/hooks/graphql/queries/use-footer'

export const Footer = () => {
  const { catalog, links, legal } = useFooterQuery()
  return (
    <Box as="footer" sx={{ padding: ['2.5rem 0 4rem 0', null, '4.5rem 0'] }}>
      <Container>
        <Flex
          sx={{
            paddingBottom: [null, null, '4.5rem'],
            flexDirection: ['column', null, 'row']
          }}
        >
          <FooterNewsletterSignup />
          <FooterLinks links={links.pages} catalog={catalog} />
        </Flex>
        <Flex
          sx={{
            justifyContent: 'space-between',
            flexDirection: ['column', null, 'row'],
            paddingTop: ['1.5rem', null, '0']
          }}
        >
          <FooterSocial />
          <FooterLegal links={legal.pages} />
          <FooterDataConsent />
        </Flex>
      </Container>
    </Box>
  )
}

export default Footer
