/** @jsx jsx */
import PropTypes from 'prop-types'
import { Text, jsx } from 'theme-ui'

const NavMenuToggle = ({ children, onClick }) => {
  return (
    <Text
      key="menu"
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        flexShrink: 0,
        variant: 'text.navLink',
        marginRight: '1rem',
        display: ['block', 'none']
      }}
    >
      {children}
    </Text>
  )
}

NavMenuToggle.propTypes = {
  onClick: PropTypes.func
}

export default NavMenuToggle
